import { Heading } from '@chakra-ui/react';

export default function FAQLanguageTitle({ lang }) {
  if (lang === 'hi') {
    return <Heading as="h1">अक्सर पूछे जाने वाले प्रश्न</Heading>;
  }
  if (lang === 'mr') {
    return <Heading as="h1">सतत विचारले जाणारे प्रश्न</Heading>;
  }
  if (lang === 'gu') {
    return <Heading as="h1">વારંવાર પૂછાતા પ્રશ્નો</Heading>;
  }
  return <Heading as="h1">Frequently asked questions</Heading>;
}
