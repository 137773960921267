import React from 'react';
import classes from './styles.module.css';
import {
  PRIVACY_POLICY_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  FAQS_PAGE,
} from '@/constants/route.const';
// import Facebook from '@/public/assets/icons/facebook.svg';
// import Instagram from '@/public/assets/icons/instagram.svg';
// import Youtube from '@/public/assets/icons/youtube.svg';
// import Telegram from '@/public/assets/icons/telegram.svg';
// import Twitter from '@/public/assets/icons/twitter.svg';
import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import Link from 'next/link';

// const SOCIAL_MEDIA_INFO = [
//   {
//     title: 'Facebook',
//     icon: Facebook,
//     link: 'https://www.facebook.com/profile.php?id=100074959262484',
//   },
//   {
//     title: 'Instagram',
//     icon: Instagram,
//     link: 'https://www.instagram.com/sikka_app/',
//   },
//   {
//     title: 'Youtube',
//     icon: Youtube,
//     link: 'https://www.youtube.com/channel/UCVw9fGdKbRI9NKvjgGfeGsA',
//   },
//   { title: 'Telegram', icon: Telegram, link: 'https://t.me/+vaT2f1bG-HYyOWI1' },
//   { title: 'Twitter', icon: Twitter, link: 'https://twitter.com/sikkaapp' },
// ];
function Footer() {
  return (
    <Flex as="footer" pr={[0, 0]} className={classes.footer}>
      <div className={classes.links}>
        <Link
          href={PRIVACY_POLICY_PAGE}
          className={classes.link}
          target="_blank"
        >
          Privacy Policy
        </Link>
        <span>|</span>
        <Link
          href={TERMS_AND_CONDITIONS_PAGE}
          className={classes.link}
          target="_blank"
        >
          Terms & Conditions
        </Link>
        <span>|</span>
        <Link href={FAQS_PAGE} className={classes.link}>
          FAQ
        </Link>
      </div>
      <Box className={classes.copyright} opacity={0.5} mt={[4, 0]}>
        Copyright ©{new Date().getFullYear()} duo.moneyhi.co
      </Box>
    </Flex>
  );
}
export default Footer;
