import React from 'react';
import classes from './styles.module.scss';
import { HOME_PAGE } from '../../constants/route.const';
import { Image, Box, Heading, Flex } from '@chakra-ui/react';
import SKButton from '../UI/SKButton/index';
import logo from '@/public/assets/moneyhi-small.svg?url';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { StorageService } from '@/service/Storage';

function Header() {
  const router = useRouter();
  const { pathname } = router;
  const isHome = pathname === '/';
  const showBg =
    pathname === '/profile' || pathname === '/verify' || pathname === '/';

  const hasAccessToken = StorageService.getAccessToken() ? true : false;

  return (
    <Flex
      as="header"
      className={classes.header}
      justifyContent={'space-between'}
    >
      <Link href={HOME_PAGE}>
        <Box className={classes.left}>
          <Flex alignItems={'center'}>
            <Image
              className={classes.logo}
              src={logo}
              alt="logo"
              h={['35px', '45px']}
              w={['40px', '50px']}
            />
            <Heading
              fontSize={['21px', '28px']}
              display={['none', null, 'block']}
              color={['#486fce']}
              fontWeight={'semibold'}
            >
              MoneyHi Duo
            </Heading>
          </Flex>
        </Box>
      </Link>
      {isHome && hasAccessToken && (
        <Link href={'/profile'}>
          <SKButton
            w={['130px', '200px']}
            h={['45px', '50px']}
            mr={'23px'}
            bgGradient="linear(to-r, #486fce,#5c82e0)"
            color="white"
            borderRadius="13px"
            fontWeight="semibold"
          >
            Wallet
          </SKButton>
        </Link>
      )}
    </Flex>
  );
}
export default Header;
